import React, { useContext } from 'react'
import { LangContext } from "../../../store/lang-context";

function PrimaryTitle(props){

    // Props
    const { content } = props

    // Context
    const { activeLang } = useContext(LangContext)

    return (
        <h1>{activeLang ? content['en'] : content['sp']} </h1>
    )
}

export default PrimaryTitle