import React, { Fragment, useState, useEffect, useReducer, useContext } from 'react'
import { LangContext } from "../../../store/lang-context";
import useHttp from "../../../hooks/useHttp";
import Input from "../../UI/Forms/Input/Input";
import Textarea from "../../UI/Forms/Textarea/Textarea";
import SecondaryTitle from "../../UI/Text/SecondaryTitle";
import PrimaryTitle from "../../UI/Text/PrimaryTitle";
import Text from "../../UI/Text/Text";
import Loader from "../../UI/Loader/Loader";
import errorFeedback from "../../UI/Feedback/ErrorFeedback/ErrorFeedback";
import successFeedback from "../../UI/Feedback/SuccessFeedback/SuccessFeedback";
import styles from './ContactUsForm.module.css'
import ErrorFeedback from "../../UI/Feedback/ErrorFeedback/ErrorFeedback";
import SuccessFeedback from "../../UI/Feedback/SuccessFeedback/SuccessFeedback";

const initialState = {
    firstName: null, firstNameTouched: null, firstNameValid: null,
    lastName: null, lastNameTouched: null, lastNameValid: null,
    email: null, emailTouched: null, emailValid: null,
    phoneNumber: null, phoneNumberTouched: null, phoneNumberValid: null,
    message: null, messageTouched: null, messageValid: null,
};

const contactUsFormReducer = (prevState, action) => {
    switch(action.type) {
        case 'FIRST_NAME_TOUCHED': {
            const { firstName } = prevState;
            return {
                ...prevState,
                firstNameTouched: true,
                firstNameValid: firstName !== null && firstName !== ''
            };
        }
        case 'FIRST_NAME_INPUT':
            return {
                ...prevState,
                firstName: action.value,
                firstNameTouched: true,
                firstNameValid: action.value !== ''
            };

        case 'LAST_NAME_TOUCHED': {
            const { lastName } = prevState;
            return {
                ...prevState,
                lastNameTouched: true,
                lastNameValid: lastName !== null && lastName !== ''
            };
        }
        case 'LAST_NAME_INPUT':
            return {
                ...prevState,
                lastName: action.value,
                lastNameTouched: true,
                lastNameValid: action.value !== ''
            };

        case 'EMAIL_TOUCHED': {
            const { email } = prevState;
            return {
                ...prevState,
                emailTouched: true,
                emailValid: email !== null && email.includes('@')
            };
        }
        case 'EMAIL_INPUT':
            return {
                ...prevState,
                email: action.value,
                emailTouched: true,
                emailValid: action.value.includes('@')
            };

        case 'PHONE_NUMBER_TOUCHED': {
            const { phoneNumber } = prevState;
            return {
                ...prevState,
                phoneNumberTouched: true,
                phoneNumberValid: phoneNumber !== null && phoneNumber !== ''
            };
        }
        case 'PHONE_NUMBER_INPUT':
            return {
                ...prevState,
                phoneNumber: action.value,
                phoneNumberTouched: true,
                phoneNumberValid: action.value !== ''
            };

        case 'MESSAGE_TOUCHED': {
            const { message } = prevState;
            return {
                ...prevState,
                messageTouched: true,
                messageValid: message !== null && message !== ''
            };
        }
        case 'MESSAGE_INPUT':
            return {
                ...prevState,
                message: action.value,
                messageTouched: true,
                messageValid: action.value !== ''
            };

        default:
            return prevState;
    }
};

function ContactUsForm(){

    // Context
    const { activeLang } = useContext(LangContext)

    // HTTP Hook
    const { isLoading, success, error, sendRequest } = useHttp()

    // States
    const [ formValid, setFormValid ] = useState(false)
    const [ formState, stateDispatcher ] = useReducer(contactUsFormReducer, initialState)
    const { firstName, firstNameTouched, firstNameValid, lastName, lastNameTouched, lastNameValid,
            email, emailTouched, emailValid, phoneNumber, phoneNumberTouched, phoneNumberValid,
            message, messageTouched, messageValid  } = formState

    // Effects
    useEffect(() => {
        setFormValid(firstNameValid && lastNameValid &&
                     emailValid && phoneNumberValid && messageValid)
    }, [firstNameValid, lastNameValid, emailValid, phoneNumberValid, messageValid]);

    // Event Handlers
    const inputChangeHandler = (event) => {
        event.stopPropagation()
        event.preventDefault()
        const target = event.target.getAttribute('data-target')
        const value = event.target.value
        stateDispatcher({type: `${target}_INPUT`, value: value})
    }

    const inputTouchedHandler = (event) => {
        event.stopPropagation()
        event.preventDefault()
        const target = event.target.getAttribute('data-target')
        stateDispatcher({type: `${target}_TOUCHED`})
    }

    const submitHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        const formData = {
            first_name: firstName, last_name: lastName, email: email, phone_number:
            phoneNumber, message: message
        }
        sendRequest('/contact_us/', {method: 'POST', body: formData})
    }

    return (
        <form className={styles['contact-us-form']} onSubmit={submitHandler}>
            {isLoading && <Loader loadingMessage={{en: 'Sending Contact Request',
                                                   sp: 'Enviando Solicitud de Contacto'}}/>}
            {error && <ErrorFeedback errorMessage={{en: 'Error while sending contact request, try again',
                                                    sp: 'Ocurrio un error intentando enviar la solicitud, intentelo de nuevo'}}/>}
            {success && <SuccessFeedback successMessage={{en: 'Your contact request has been sent',
                                                          sp: 'Su solicitud de contacto fue enviada'}}/>}
            {!isLoading && !error && !success &&
                <Fragment>
                    <SecondaryTitle content={{en: 'Do you need assistance?', sp: '¿Tienes alguna consulta?'}}/>
                    <PrimaryTitle content={{en: 'Contact Us', sp: 'Contactanos'}}/>
                    <Text content={{
                        en: 'We\'d Love to Hear from You! If you have any questions, suggestions, or need assistance, please feel free to get in touch with us. Fill out the form below, and one of our team members will get back to you as soon as possible. We are here to help!',
                        sp: '¡Nos Encantaría Saber de Ti! Si tienes alguna pregunta, sugerencia o necesitas asistencia, no dudes en ponerte en contacto con nosotros. Completa el formulario a continuación y uno de los miembros de nuestro equipo se pondrá en contacto contigo lo antes posible. ¡Estamos aquí para ayudarte!'
                    }}/>
                    <Input type={'text'}
                           label={{en: '*First Name', sp: '*Primer Nombre'}}
                           placeholder={{en: 'Provide your first name', sp: 'Ingrese su primer nombre'}}
                           errorMessage={{en: 'Provide a valid first name', sp: 'Ingrese un nombre valido'}}
                           isTouched={firstNameTouched}
                           isValid={firstNameValid}
                           value={firstName}
                           required={true}
                           dataTarget={'FIRST_NAME'}
                           inputTouchedHandler={inputTouchedHandler}
                           inputChangeHandler={inputChangeHandler}/>
                    <Input type={'text'}
                          label={{en: '*Last Name', sp: '*Apellido'}}
                           placeholder={{en: 'Provide your last name', sp: 'Ingrese su apellido'}}
                           errorMessage={{en: 'Provide a valid last name', sp: 'Ingrese un apellido valido'}}
                           isTouched={lastNameTouched}
                           isValid={lastNameValid}
                           value={lastName}
                           required={true}
                           dataTarget={'LAST_NAME'}
                           inputTouchedHandler={inputTouchedHandler}
                           inputChangeHandler={inputChangeHandler}/>
                    <Input type={'email'}
                           label={{en: '*Email', sp: '*Correo Electronico'}}
                           placeholder={{en: 'Provide your email', sp: 'Ingrese su correo electronico'}}
                           errorMessage={{en: 'Provide a valid email', sp: 'Ingrese un correo valido'}}
                           isTouched={emailTouched}
                           isValid={emailValid}
                           value={email}
                           required={true}
                           dataTarget={'EMAIL'}
                           inputTouchedHandler={inputTouchedHandler}
                           inputChangeHandler={inputChangeHandler}/>
                    <Input type={'text'}
                           label={{en: '*Phone Number', sp: '*Numero de Celular'}}
                           placeholder={{en: 'Provide your phone number', sp: 'Ingrese su numero celular'}}
                           errorMessage={{en: 'Provide a valid phone number', sp: 'Ingrese un numero valido'}}
                           isTouched={phoneNumberTouched}
                           isValid={phoneNumberValid}
                           value={phoneNumber}
                           required={true}
                           dataTarget={'PHONE_NUMBER'}
                           inputTouchedHandler={inputTouchedHandler}
                           inputChangeHandler={inputChangeHandler}/>
                    <Textarea label={{en: '*Message', sp: '*Mensaje'}}
                              placeholder={{en: 'Provide a message', sp: 'Ingrese sus comentarios'}}
                              errorMessage={{en: 'Provide a valid message', sp: 'Ingrese un mensaje valido'}}
                              isTouched={messageTouched}
                              isValid={messageValid}
                              value={message}
                              required={true}
                              dataTarget={'MESSAGE'}
                              inputTouchedHandler={inputTouchedHandler}
                              inputChangeHandler={inputChangeHandler}/>
                    <button type={'submit'}
                            disabled={!formValid}>
                        {activeLang ? 'Send Message' : 'Enviar Mensaje'}
                    </button>
                </Fragment>}
        </form>
    )
}

export default ContactUsForm