import React, { useContext } from 'react'
import { LangContext } from "../../../store/lang-context";
import { Icon } from "@iconify/react";
import { NavLink } from "react-router-dom";
import logo from './../../../assets/logo.png'
import styles from './Menu.module.css'

function Menu(props){

    // Props
    const {onCloseModal: closeModalHandler } = props

    // Context
    const { activeLang, toggleLangHandler } = useContext(LangContext)

    return (
        <div className={styles['menu']}>
            <div className={styles['menu-header']}>
                <img className={styles['menu-logo']} src={logo} alt={'menu-logo'}/>
                <Icon className={styles['menu-toggle']} onClick={closeModalHandler} icon="formkit:close" width="24" height="24"  style={{color: '#FFFFFF'}} />
            </div>
            <div className={styles['menu-content']}>
                <NavLink to={''} className={styles['menu-link']} onClick={closeModalHandler}>
                    <Icon className={styles['link-icon']} icon="bi:house"/>
                    <span>{activeLang ? 'Home' : 'Inicio'}</span>
                </NavLink>
                <NavLink to={'products'} className={styles['menu-link']} onClick={closeModalHandler}>
                    <Icon className={styles['link-icon']} icon="solar:watch-round-linear"/>
                    <span>{activeLang ? 'Products' : 'Productos'}</span>
                </NavLink>
                <NavLink to={'quotations'} className={styles['menu-link']} onClick={closeModalHandler}>
                    <Icon className={styles['link-icon']} icon="pepicons-pencil:handshake"/>
                    <span>{activeLang ? 'Special Orders' : 'Pedidos Especiales'}</span>
                </NavLink>
                <NavLink to={'contact-us'} className={styles['menu-link']} onClick={closeModalHandler}>
                    <Icon className={styles['link-icon']} icon="fluent:chat-28-regular"/>
                    <span>{activeLang ? 'Contact Us' : 'Contactanos'}</span>
                </NavLink>
                <NavLink to={'reviews'} className={styles['menu-link']} onClick={closeModalHandler}>
                    <Icon className={styles['link-icon']} icon="material-symbols-light:reviews-outline"/>
                    <span>{activeLang ? 'Reviews' : 'Reseñas'}</span>
                </NavLink>
                <NavLink to={'faqs'} className={styles['menu-link']} onClick={closeModalHandler}>
                    <Icon className={styles['link-icon']} icon="ph:question-light"/>
                    <span>{activeLang ? 'FAQs' : 'Preguntas Frecuentes'}</span>
                </NavLink>
            </div>
            <div className={styles['menu-footer']}>
                {!activeLang && <Icon className={styles['lang-selector']} onClick={toggleLangHandler} icon="twemoji:flag-united-kingdom"/>}
                {activeLang && <Icon className={styles['lang-selector']} onClick={toggleLangHandler} icon="twemoji:flag-spain"/>}
            </div>
        </div>
    )
}

export default Menu