import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import PrimaryTitle from './../../../components/UI/Text/PrimaryTitle'
import SecondaryTitle from "../../../components/UI/Text/SecondaryTitle";
import Text from './../../../components/UI/Text/Text'
import ProductSpecs from "../../../components/Products/ProductSpecs/ProductSpecs";
import ContactSeller from "../../../components/Products/ContactSeller/ContactSeller";
import styles from './ProductDetail.module.css'
import useHttp from "../../../hooks/useHttp";

function ProductDetail(){

    // URL Params
    const { productId } = useParams()

    // HTTP Hook
    const { baseUrl, isLoading, error, success, data, sendRequest } = useHttp()

    // States
    const [ model, setModel ] = useState(null)

    // Effects
    useEffect(() => {
        sendRequest(`/products/${productId}`)
    }, []);

    useEffect(() => {
        success && data && setModel(data.model)
    }, [success, data]);

    return (
        <div className={styles['product-detail']}>
            <div className={styles['product-viewer']}>
                <img className={styles['product-image']} src={`${baseUrl}${model?.images[0].image}`} alt={'Product Image'}/>
            </div>
            <div className={styles['product-info']}>
                <SecondaryTitle content={{en: model?.brand, sp: model?.brand}}/>
                <PrimaryTitle content={{en: model?.name, sp: model?.name}}/>
                <SecondaryTitle content={{en: 'Description', sp: 'Descripcion'}}/>
                <Text content={{en: model?.description,
                                sp: model?.description_sp}}/>
                <SecondaryTitle content={{en: 'Product Details', sp: 'Detalles del Producto'}}/>
                <ProductSpecs product={data}/>
                <ContactSeller/>
            </div>
        </div>
    )
}

export default ProductDetail