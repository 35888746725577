import React from 'react'
import {Icon} from "@iconify/react";
import Text from "../../../../UI/Text/Text";
import styles from './ReviewUser.module.css'

function ReviewUser(){

    return (
        <div className={styles['review-user']}>
            <Icon className={styles['user-pp']} icon="fa-solid:user-circle"/>
            <Text content={{en: 'Anonymous User', sp: 'Usuario Anonimo'}}/>
        </div>
    )

}

export default ReviewUser