import React from 'react'
import ReactDOM from "react-dom";
import styles from './Modal.module.css'

function Modal(props){

    const { modalOpened, onCloseModal: closeModalHandler, classes, children } = props

    if (!modalOpened) return null

    return ReactDOM.createPortal(
        <div className={`${styles['modal']} ${classes}`} onClick={closeModalHandler}>
            <div className={styles['modal-content']} onClick={(event) => {event.stopPropagation()}}>
                {children}
            </div>
        </div>,
        document.getElementById('modal')
    )

}

export default Modal
