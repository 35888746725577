import React, { useContext } from 'react'
import { LangContext } from "../../../store/lang-context";
import PrimaryTitle from "../../../components/UI/Text/PrimaryTitle";
import SecondaryTitle from "../../../components/UI/Text/SecondaryTitle";
import Text from "../../../components/UI/Text/Text";
import styles from './Inspiration.module.css'

function Inspiration(){

    const { activeLang } = useContext(LangContext)

    return (
        <div className={styles['inspiration']}>
            <SecondaryTitle content={{en: 'Elegance In Every Timepiece', sp: 'Elegancia En Cada Pieza'}}/>
            <PrimaryTitle content={{en: 'Timeless Style for Every Occasion', sp: 'Estilo Para Cada Ocasion'}}/>
            <Text content={{en: 'Elegance is more than a look; it’s a way of being. We believe in the power of refined style, where every moment is an opportunity to express grace and sophistication. Our vision is rooted in the timeless qualities of beauty, precision, and attention to detail, allowing you to carry these values effortlessly in your everyday life. With an unwavering commitment to excellence, we celebrate the art of living with style, through timepieces.',
                            sp: 'La elegancia es más que una apariencia; es una forma de ser. Creemos en el poder del estilo refinado, donde cada momento es una oportunidad para expresar gracia y sofisticación. Nuestra visión se basa en las cualidades atemporales de la belleza, la precisión y la atención al detalle, permitiéndote llevar estos valores con facilidad en tu vida diaria. Con un compromiso inquebrantable con la excelencia, celebramos el arte de vivir con estilo.'}}/>

            {/* Embedding YouTube video */}
            <div className={styles['video-container']}>
                <iframe
                    className={styles['inspo-video']}
                    src={`https://www.youtube.com/embed/${activeLang ? 'PFjYt66h_i4' : 'XKx1xEZx8rs'}`}
                    title="8 Things You NEED to Know About Watches - A Crash Course to Watches"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
                </iframe>
            </div>
        </div>
    )
}

export default Inspiration;
