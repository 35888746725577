import React, { useContext } from 'react'
import { LangContext } from "../../../store/lang-context";
import styles from './ProductSpecs.module.css'

function ProductSpecs(props){

    // Props
    const { product } = props
    const { model } = product

    // Context
    const { activeLang } = useContext(LangContext)

    return (
        <table className={styles['product-specs']}>
            <tbody>
            <tr>
                <td className={styles['spec-title']}>{activeLang ? 'Category' : 'Categoria'}</td>
                <td className={styles['spec-value']}>{activeLang ? model?.category.en : model?.category.sp}</td>
            </tr>
            <tr>
                <td className={styles['spec-title']}>{activeLang ? 'Movement' : 'Movimiento'}</td>
                <td className={styles['spec-value']}>{activeLang ? model?.movement.en : model?.movement.sp}</td>
            </tr>
            <tr>
                <td className={styles['spec-title']}>{activeLang ? 'Case Diameter' : 'Diametro de Bisel'}</td>
                <td className={styles['spec-value']}>{`${model?.case_diameter}mm`}</td>
            </tr>
            <tr>
                <td className={styles['spec-title']}>{activeLang ? 'Dial Color' : 'Color de Dial'}</td>
                <td className={styles['spec-value']}>{activeLang ? model?.dial_color.en : model?.dial_color.sp}</td>
            </tr>
            <tr>
                <td className={styles['spec-title']}>{activeLang ? 'Material' : 'Material'}</td>
                <td className={styles['spec-value']}>{activeLang ? model?.material[0].en : model?.material[0].sp}</td>
            </tr>
            <tr>
                <td className={styles['spec-title']}>{activeLang ? 'Strap Type' : 'Tipo de Correa'}</td>
                <td className={styles['spec-value']}>{activeLang ? model?.strap_type.en : model?.strap_type.sp}</td>
            </tr>
            <tr>
                <td className={styles['spec-title']}>{activeLang ? 'Water Resistance' : 'Resistencia al Agua'}</td>
                <td className={styles['spec-value']}>{`${model?.water_resistance} mts`}</td>
            </tr>
            <tr>
                <td className={styles['spec-title']}>{activeLang ? 'Serial Number' : 'Numero de Serie'}</td>
                <td className={styles['spec-value']}>{model?.serial_number}</td>
            </tr>
            {parseInt(product.discount) !== 0 && (
                <tr>
                    <td className={styles['spec-title']}>{activeLang ? 'Discount' : 'Descuento'}</td>
                    <td className={styles['spec-value']}>{`${product?.discount}%`}</td>
                </tr>
            )}
            <tr>
                <td className={styles['spec-title']}>{activeLang ? 'Price' : 'Precio'}</td>
                <td className={styles['spec-value']}>{`Lps. ${(product?.price * (1 - product?.discount / 100)).toFixed(2)}`}</td>
            </tr>
            </tbody>
        </table>
    )
}

export default ProductSpecs