import React from 'react'
import styles from './BrandTile.module.css'

function BrandTile(props){

    const { brand } = props

    return (
        <div className={styles['brand-tile']}>
            <img className={styles['brand-image']} src={brand.image} alt={'Brand Image'}/>
            <p className={styles['brand-name']}>{brand.name}</p>
        </div>
    )
}

export default BrandTile