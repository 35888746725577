import React, {useEffect} from 'react'
import { Link } from "react-router-dom";
import useHttp from "../../../hooks/useHttp";
import SecondaryTitle from "../../../components/UI/Text/SecondaryTitle";
import BrandTile from "../../../components/Products/Brands/BrandTile/BrandTile";
import Loader from "../../../components/UI/Loader/Loader";
import ErrorFeedback from "../../../components/UI/Feedback/ErrorFeedback/ErrorFeedback";
import NoDataFeedBack from "../../../components/UI/Feedback/NoDataFeedback/NoDataFeedBack";
import styles from './Brands.module.css'

function OurBrands(){

    const { baseUrl, isLoading, error, success, data, sendRequest } = useHttp()

    useEffect(() => {
        sendRequest('/products/brands')
    }, []);

    return (
        <div className={styles['brands']}>
            <SecondaryTitle content={{en: 'Our Brands', sp: 'Nuestras Marcas'}}/>
            <Link to={'all'}>
                <button className={styles['all-products-btn']}>See All Products</button>
            </Link>
            {isLoading && <Loader/>}
            {error && !success && !data && <ErrorFeedback/>}
            {!error && success && data.brands?.length === 0 && <NoDataFeedBack/>}
            {!error && success && data &&
                <div className={styles['brands-gallery']}>
                    {data.brands.map((brand, index) => {
                        return <BrandTile key={index} brand={brand} baseUrl={baseUrl}/>
                    })}
                </div>
            }
        </div>
    )

}

export default OurBrands