import React, { Fragment, useState, useEffect } from 'react';
import ReviewCard from "./ReviewCard/ReviewCard";
import styles from './ReviewsGallery.module.css';
import Modal from "../../UI/Modal/Modal";
import ReviewForm from "../ReviewForm/ReviewForm";
import ReviewsController from "../ReviewsController/ReviewsController";

function ReviewsGallery(props){

    // Props
    const { reviews } = props;

    // Reviews States
    const [reviewFormModalActive, setReviewFormModalActive] = useState(false);
    const [orderBy, setOrderBy] = useState(null);
    const [sortedReviews, setSortedReviews] = useState([...reviews]);

    // Effects
    useEffect(() => {
        if (orderBy) {
            let sortedArray = [...reviews];
            switch(orderBy) {
                case 'rating-desc':
                    sortedArray.sort((a, b) => b.rating - a.rating);
                    break;
                case 'rating-asc':
                    sortedArray.sort((a, b) => a.rating - b.rating);
                    break;
                case 'created-desc':
                    sortedArray.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    break;
                case 'created-asc':
                    sortedArray.sort((a, b) => new Date(a.created_at) - new Date(b.created_At));
                    break;
                default:
                    break;
            }
            setSortedReviews(sortedArray);
        }
    }, [orderBy, reviews]);

    // Event Handlers
    const toggleReviewFormModalHandler = () => {
        setReviewFormModalActive((prevState) => !prevState);
    };

    const addReviewHandler = (review) => {
        setSortedReviews((prevState) => {
            return [review, ...prevState]
        })
    }

    const changeOrderHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setOrderBy(event.target.value);
    };

    return (
        <Fragment>
            <ReviewsController changeOrder={changeOrderHandler} toggleReviewFormModal={toggleReviewFormModalHandler}/>
            <div className={styles['reviews-gallery']}>
                {sortedReviews && sortedReviews.map((review, index) => (
                    <ReviewCard key={index} review={review} />
                ))}
            </div>
            <Modal modalOpened={reviewFormModalActive}
                   onCloseModal={toggleReviewFormModalHandler}>
               <ReviewForm addNewReview={addReviewHandler}
                           toggleReviewFormModal={toggleReviewFormModalHandler}/>
            </Modal>
        </Fragment>
    );
}

export default ReviewsGallery;