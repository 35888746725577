import React, { useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { LangContext } from "../../../../../store/lang-context";
import SecondaryTitle from "../../../../UI/Text/SecondaryTitle";
import styles from './ProductCard.module.css'

function ProductCard(props){

    // Props
    const { product, baseUrl } = props
    const { model } = product

    // Context
    const { activeLang } = useContext(LangContext)

    // Hooks
    const navigate = useNavigate()

    // Handlers
    const productCardClickHandler = () => {
        navigate(`${product.id}`)
    }

    return (
        <div className={styles['product-card']} onClick={productCardClickHandler}>
            <img src={`${baseUrl}${model.images[0].image}`} className={styles['product-image']} alt={'Product Image'}/>
            <SecondaryTitle content={{en: `${model.brand} ${model.name}`, sp: `${model.brand} ${model.name}`}}/>
            <small>{`${activeLang ? model.movement.en : model.movement.sp } - ${model.case_diameter}mm - ${activeLang ? model.category.en : model.category.sp }`}</small>
            <small>{model.serial_number}</small>
            <div className={styles['product-card-footer']}>
                <p className={styles['product-price']}>{`Lps. ${(product.price * (1 - product.discount / 100)).toFixed(2)}`}</p>
                {parseInt(product.discount) > 0 && <small className={styles['product-prev-price']}>{`Lps. ${product.price.toFixed(2)}`}</small>}
            </div>
        </div>
    )
}

export default ProductCard