import React, {Fragment, useEffect} from 'react'
import {Routes, Route, useParams} from "react-router-dom";
import useHttp from "../../../hooks/useHttp";
import BrandProfile from "../../../components/Products/BrandStore/BrandProfile/BrandProfile";
import ProductCatalog from "../../../components/Products/ProductCatalog/ProductCatalog";
import ProductDetail from "../ProductDetail/ProductDetail";
import Loader from "../../../components/UI/Loader/Loader";
import styles from './BrandStore.module.css'
import ErrorFeedback from "../../../components/UI/Feedback/ErrorFeedback/ErrorFeedback";


function BrandStore(){

    // URL Parameters
    const { brandId } = useParams()

    // HTTP Hook
    const { baseUrl, isLoading, error, success, data, sendRequest } = useHttp()

    // Effects
    useEffect(() => {
        sendRequest(`/products/filter/${brandId}`)
    }, []);

    return (
        <div className={styles['brand-store']}>
            {isLoading && <Loader/>}
            {error && <ErrorFeedback/>}
            {success && data && (
              <Routes>
                <Route path={''} element={<Fragment>
                                            <BrandProfile brand={data.brand} baseUrl={baseUrl}/>
                                            <ProductCatalog products={data.products}/>
                                        </Fragment>}/>
                <Route path={':productId'} element={<ProductDetail/>}/>
            </Routes>
            )}
        </div>
    )
}

export default BrandStore