import React, { useState, useContext } from 'react'
import {LangContext} from "../../../store/lang-context";
import { Icon } from "@iconify/react";
import Text from "../../UI/Text/Text";
import styles from './Faq.module.css'

function Faq(props){

    // Props
    const { question, answer } = props

    // Context
    const { activeLang } = useContext(LangContext)

    // States
    const [ answerDisplayed, setAnswerDisplayed ] = useState(false)

    // Event Handlers
    const toggleFaq = () => {
        setAnswerDisplayed((prevState) => {
            return !prevState
        })
    }

    return (
        <div className={styles['faq']} onClick={toggleFaq}>
            <div className={styles['faq-header']}>
                <Text classes={styles['faq-question']} content={question}/>
                <Icon className={`${styles['faq-arrow']} ${answerDisplayed && styles['faq-arrow-flip']}`}
                      icon="iconamoon:arrow-down-2-light"/>
            </div>
            {answerDisplayed &&
                <div className={styles['faq-body']}>
                    <Text classes={styles['faq-answer']} content={answer}/>
                </div>
            }
        </div>
    )
}

export default Faq