import React, { useContext } from 'react'
import { LangContext } from "../../../../store/lang-context";
import { Icon } from "@iconify/react";
import styles from './Quality.module.css'

function Quality(props){

    // Props
    const { icon, quality } = props

    // Context
    const { activeLang } = useContext(LangContext)

    return (
        <div className={styles['quality']}>
            <Icon className={styles['quality-icon']} icon={icon}/>
            <p>{activeLang ? quality.en : quality.sp}</p>
        </div>
    )
}

export default Quality

