import React, {useCallback, useContext} from 'react'
import { LangContext } from "../../../../store/lang-context";
import styles from './Input.module.css'

function Input(props){

    // Props
    const { dataTarget, type, label, errorMessage, placeholder, isTouched, isValid,
            value, required, inputTouchedHandler, inputChangeHandler} = props

    // Context
    const { activeLang } = useContext(LangContext);

    // Helper Functions
    const getInputClass = useCallback(() => isTouched && isValid === false ? styles['invalid-input'] : '', [isTouched, isValid]);

    return (
        <div className={styles['input']}>
            <div className={styles['input-header']}>
                <label className={styles['input-label']}>{activeLang ? label?.en : label?.sp}</label>
                {isValid === false && (
                    <label className={styles['input-error-label']}>{activeLang ? errorMessage?.en : errorMessage?.sp}</label>
                )}
            </div>
            <input type={type ? type : 'text'}
                   placeholder={placeholder && (activeLang ? placeholder?.en : placeholder?.sp)}
                   className={getInputClass()}
                   onFocus={inputTouchedHandler}
                   onInput={inputChangeHandler}
                   value={value}
                   required={required ? required : false}
                   data-target={dataTarget}/>
        </div>
    )

}

export default Input