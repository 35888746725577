import React from 'react'
import PrimaryTitle from "../../../components/UI/Text/PrimaryTitle";
import SecondaryTitle from "../../../components/UI/Text/SecondaryTitle";
import Text from "../../../components/UI/Text/Text";
import Quality from "../../../components/Home/AboutUs/Quality/Quality";
import dressWatchImg from './../../../assets/Home/images/inspiration.webp'
import styles from './AboutUs.module.css'

function AboutUs(){
    return (
        <div className={styles['about-us']}>
            <div className={styles['content']}>
                <SecondaryTitle content={{en: 'About Us', sp: 'Sobre Nosotros'}}/>
                <PrimaryTitle content={{en: 'Precision Meets Quality', sp: 'Precision y Calidad'}}/>
                <Text content={{en: 'Precision and quality define everything we strive for. We believe that accuracy is not just a feature but a necessity, ensuring every detail meets the highest standards. In a world where reliability matters, our commitment to delivering uncompromising quality remains unwavering. Each second counts, and with our focus on precision, we bring you the assurance of consistent excellence and timeless value. Our goal is simple: to provide you with a refined experience that stands the test of time.',
                                sp: 'La precisión y la calidad son los pilares de todo lo que buscamos. Creemos que la exactitud no es solo una característica, sino una necesidad, asegurando que cada detalle cumpla con los más altos estándares. En un mundo donde la confiabilidad es esencial, nuestro compromiso con una calidad inigualable es inquebrantable. Cada segundo cuenta, te ofrecemos la certeza de una excelencia constante y un valor atemporal. Nuestro objetivo es simple: brindarte una experiencia refinada que resista el paso del tiempo.'}}/>
                <div className={styles['qualities']}>
                    <Quality icon={'solar:shield-outline'} quality={{en:'Quality', sp:'Calidad'}}/>
                    <Quality icon={'system-uicons:diamond'} quality={{en:'Elegance', sp:'Elegancia'}}/>
                    <Quality icon={'ph:watch-light'} quality={{en:'Precise', sp:'Precisos'}}/>
                    <Quality icon={'mdi:stars-outline'} quality={{en:'Exclusive', sp:'Exclusivos'}}/>
                </div>
            </div>
            <div className={styles['visualizer']}>
                <img className={styles['about-us-img']} src={dressWatchImg} alt={'Classic Watch'}/>
            </div>
        </div>
    )
}

export default AboutUs