import React, { useEffect } from 'react'
import SecondaryTitle from "../../components/UI/Text/SecondaryTitle";
import ReviewsGallery from "../../components/Reviews/ReviewsGallery/ReviewsGallery";
import useHttp from "../../hooks/useHttp";
import Loader from "../../components/UI/Loader/Loader";
import ErrorFeedback from "../../components/UI/Feedback/ErrorFeedback/ErrorFeedback";
import NoDataFeedBack from "../../components/UI/Feedback/NoDataFeedback/NoDataFeedBack";
import styles from './Reviews.module.css'

function Reviews(){

    // Data Extraction
    const { isLoading, error, success, data, sendRequest } = useHttp()

    // Effects
    useEffect(() => {
        sendRequest('/reviews')
    }, [])

    return (
        <div className={styles['reviews']}>
            <SecondaryTitle content={{en: 'Reviews', sp: 'Reseñas'}}/>
            {!error && success && data && <ReviewsGallery reviews={data.reviews}/>}
            {isLoading && <Loader/>}
            {error && !success && !data && <ErrorFeedback/>}
            {!error && success && data.reviews?.length === 0 && <NoDataFeedBack/>}
        </div>
    )
}

export default Reviews