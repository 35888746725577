import React from 'react'
import { Routes, Route } from "react-router-dom";
import Brands from "./Brands/Brands";
import BrandStore from "./BrandStore/BrandStore";
import AllProducts from "./AllProducts/AllProducts";

function Products(){
    return (
        <Routes>
            <Route path={''} element={<Brands/>}/>
            <Route path={':brandId/*'} element={<BrandStore/>}/>
            <Route path={'all/*'} element={<AllProducts/>}/>
        </Routes>
    )
}

export default Products