import React, { useContext } from 'react'
import { LangContext } from "../../../store/lang-context";
import Select from "../../UI/Forms/Select/Select";
import styles from './ReviewsController.module.css'

const filterOptions = [{value: 'rating-desc', text: {en: 'Rating (Descending)', sp: 'Calificacion (Descendente)'}},
                       {value: 'rating-asc', text: {en: 'Rating (Ascending)', sp: 'Calificacion (Ascendente)'}},
                       {value: 'created-desc', text: {en: 'Created Date (Latest Reviews)', sp: 'Fecha Creada (Ultimas Reseñas)'}},
                       {value: 'created-asc', text: {en: 'Created Date (Oldest Reviews)', sp: 'Fecha Creada (Reseñas Antiguas)'}}]

function ReviewsController(props){

    // Props
    const { changeOrder, toggleReviewFormModal } = props

    // Context
    const { activeLang } = useContext(LangContext)

    return (
        <div className={styles['reviews-controller']}>
            <Select placeholder={{en: 'Filter Reviews', sp: 'Filtrar Reseñas'}} classes={styles['review-filter']}
                    inputChangeHandler={changeOrder} options={filterOptions}/>
            <button className={styles['create-review-button']}
                    onClick={toggleReviewFormModal}
                    type={'button'}>
                {activeLang ? 'Create Review' : 'Crear Reseña'}
            </button>
        </div>
    )
}

export default ReviewsController