import React, { useState, useEffect } from 'react'
import useHttp from "../../hooks/useHttp";
import Loader from "../../components/UI/Loader/Loader";
import ErrorFeedback from "../../components/UI/Feedback/ErrorFeedback/ErrorFeedback";
import QuotationForm from "../../components/Quotations/QuotationForm/QuotationForm";
import ProductDisplay from "../../components/Quotations/ProductDisplay/ProductDisplay";
import styles from './Quotations.module.css'

function Quotations(){

    // States
    const [ selectedModel, setSelectedModel ] = useState(null)

    // HTTP Hook
    const { baseUrl, isLoading, success, error, data, sendRequest } = useHttp()

    // Effects
    useEffect(() => {
        sendRequest('/quotations')
    }, [])

    // Event Handlers
    const displayModel = (model) => {
        setSelectedModel(model)
    }

    return (
        <div className={styles['quotations']}>
            {isLoading && <Loader/>}
            {error && <ErrorFeedback/>}
            {success && data && (
                <QuotationForm brands={data.brands}
                               products={data.products}
                               displayModelHandler={displayModel}/>
            )}
            <ProductDisplay baseUrl={baseUrl} model={selectedModel}/>
        </div>
    )
}

export default Quotations