import React, {useCallback, useContext} from 'react';
import { LangContext } from "../../../../store/lang-context";
import { Icon } from "@iconify/react";
import styles from './SuccessFeedback.module.css';

function SuccessFeedback(props) {

    // Props
        const { classes, successMessage } = props

    // Context
    const { activeLang } = useContext(LangContext);

    // Helper Functions
    const getClasses = useCallback(() => classes ? `${classes} ${styles['success']}` : styles['success'], [])

    return (
        <div className={getClasses()}>
            <Icon className={styles['success-icon']} icon="material-symbols:sentiment-excited-outline-rounded" />
            <p className={styles['success-text']}>
                {successMessage && (activeLang ? successMessage.en : successMessage.sp)}
                {!successMessage && (activeLang ? 'Operation was finished successfully!' : '¡La operacion finalizo correctamente!')}
            </p>
        </div>
    );
}

export default SuccessFeedback;
