import React, { useContext } from 'react';
import { LangContext } from "../../../../store/lang-context";
import { Icon } from "@iconify/react";
import styles from './NoDataFeedback.module.css';

function NoDataFeedBack(props) {

    // Props
    const { noDataMessage } = props

    // Context
    const { activeLang } = useContext(LangContext);

    return (
        <div className={styles['no-data']}>
            <Icon className={styles['no-data-icon']} icon="streamline:sad-face" />
            <p className={styles['no-data-text']}>
                {!noDataMessage && (activeLang ? 'Oops! No data available right now' : '¡Ups! No hay datos disponibles en este momento')}
                {noDataMessage && (activeLang ? noDataMessage.en : noDataMessage.sp)}
            </p>
        </div>
    );
}

export default NoDataFeedBack;
