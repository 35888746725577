import React, { useCallback, useContext } from 'react';
import { LangContext } from "../../../../store/lang-context";
import styles from './Select.module.css';

function Select(props) {
    // Props
    const { dataTarget, label, errorMessage, isTouched, isValid, classes, placeholder,
            value, required, options, inputTouchedHandler, inputChangeHandler } = props;

    // Context
    const { activeLang } = useContext(LangContext);

    // Helper Functions
    const getInputClass = useCallback(() => isTouched && isValid === false ? styles['invalid-input'] : '', [isTouched, isValid]);

    return (
        <div className={`${styles['input']} ${classes}`}>
            <div className={styles['input-header']}>
                {label && <label className={styles['input-label']}>{activeLang ? label?.en : label?.sp}</label>}
                {isValid === false && (
                    <label className={styles['input-error-label']}>{activeLang ? errorMessage?.en : errorMessage?.sp}</label>
                )}
            </div>
            <select className={getInputClass()}
                    onFocus={inputTouchedHandler}
                    onInput={inputChangeHandler}
                    value={value}
                    required={required ? required : false}
                    data-target={dataTarget}>
                    <option disabled selected={true} value={null}>{placeholder && (activeLang ? placeholder?.en : placeholder?.sp)}</option>
                    {options?.map((option, index) => <option key={index} value={option.value}>
                        {activeLang ? option.text.en : option.text.sp}
                    </option>)}
            </select>
        </div>
    );
}

export default Select;