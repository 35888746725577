import React, { Fragment, useState, useEffect, useReducer, useContext } from 'react'
import { LangContext } from "../../../store/lang-context";
import useHttpForm from "../../../hooks/useHttpForm";
import SecondaryTitle from "../../UI/Text/SecondaryTitle";
import ReviewRating from "../ReviewRating/ReviewRating";
import Input from "../../UI/Forms/Input/Input";
import Select from "../../UI/Forms/Select/Select";
import Textarea from "../../UI/Forms/Textarea/Textarea";
import placeholderImg from './../../../assets/Reviews/UploadImage.png'
import Loader from "../../UI/Loader/Loader";
import ErrorFeedback from "../../UI/Feedback/ErrorFeedback/ErrorFeedback";
import SuccessFeedback from "../../UI/Feedback/SuccessFeedback/SuccessFeedback";
import styles from './ReviewForm.module.css'

// Form Labels
const reviewFormLabels = {
    rating: {
        labels: {en: 'Rating', sp: 'Calificacion'},
        placeholders: {en: 'Select Rating Value', sp: 'Seleccione Una Calificacion'},
        errorMessages: {en: 'Select A Valid Rating Value', sp: 'Seleccione Una Calificacion Valida'}
    },
    title: {
        labels: {en: 'Title', sp: 'Titulo'},
        placeholders: {en: 'Provide a Title', sp: 'Indique un Titulo'},
        errorMessages: {en: 'Provide a Valid Title', sp: 'Indique Un Titulo Valido'}
    },
    message: {
        labels: {en: 'Message', sp: 'Mensaje'},
        placeholders: {en: 'Provide a Message', sp: 'Indique un Mensaje'},
        errorMessages: {en: 'Provide a Valid Message', sp: 'Indique Un Mensaje Valido'}
    }
}

// Initial State
const initialState = {
    rating: null, ratingTouched: null, ratingValid: null,
    title: null, titleTouched: null, titleValid: null,
    message: null, messageTouched: null, messageValid: null,
    image: null, imagePreview: null
}

// Review Form Reducer
function reviewFormReducer(prevState, action){
    switch(action.type){
        case 'RATING_TOUCHED':
            return {
                ...prevState,
                ratingTouched: true,
                ratingValid: prevState.rating !== null && prevState.rating >= 1
            }
        case 'RATING_INPUT':
            const ratingValue = parseInt(action.value)
            return {
                ...prevState,
                rating: ratingValue,
                ratingValid: ratingValue >= 1
            }
        case 'TITLE_TOUCHED':
            return {
                ...prevState,
                titleTouched: true,
                titleValid: prevState.title !== null && prevState.title !== ''
            }
        case 'TITLE_INPUT':
            const titleValue = action.value
            return {
                ...prevState,
                title: titleValue,
                titleValid: titleValue !== ''
            }
        case 'MESSAGE_TOUCHED':
            return {
                ...prevState,
                messageTouched: true,
                messageValid: prevState.message !== null && prevState.message !== ''
            }
        case 'MESSAGE_INPUT':
            const messageValue = action.value
            return {
                ...prevState,
                message: messageValue,
                messageValid: messageValue !== ''
            }
        case 'IMAGE_INPUT':
            return {
                ...prevState,
                image: action.file,
                imagePreview: action.preview
            };
        default:
            return prevState
    }
}

function ReviewForm(props){
    // Props
    const { addNewReview, toggleReviewFormModal } = props

    // Constants
    const {rating: reviewRating, title: reviewTitle, message: reviewMessage} = reviewFormLabels

    // Context
    const { activeLang } = useContext(LangContext)

    // Reducer Hook
    const [formState, dispatch] = useReducer(reviewFormReducer, initialState);

    // HTTP Hook
    const { isLoading, error, success, data, sendRequest } = useHttpForm()

    //  States
    const { image, rating, ratingTouched, ratingValid,  title, titleTouched, titleValid,
            message, messageTouched, messageValid } = formState
    const [ formValid, setFormValid ] = useState(false)

    // Effects
    useEffect(() => {
        setFormValid(ratingValid && titleValid && messageValid)
    }, [ratingValid, titleValid, messageValid])

    useEffect(() => {
        if (success && data){
            addNewReview(data.data.review)
            setTimeout(() => toggleReviewFormModal(), 2000)
        }
    }, [success, data])

    useEffect(() => {
        error && setTimeout(() => toggleReviewFormModal(), 2000)
    }, [error])

    // Event Handlers
    const imageChangeHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            const preview = URL.createObjectURL(file);
            dispatch({ type: 'IMAGE_INPUT', file: file, preview: preview });
        }
    };

    const inputChangeHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        const target = event.target.getAttribute('data-target')
        const value = event.target.value
        dispatch({type: `${target}_INPUT`, value:value})
    }

    const inputTouchedHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        const target = event.target.getAttribute('data-target')
        dispatch({type: `${target}_TOUCHED`})
    }

    const formSubmitHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (formValid) {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('review', message);
        formData.append('rating', rating);
        if (image) {
            formData.append('image', image);
        }
        sendRequest('/reviews/', formData);
    }
};

    return (
        <form className={styles['review-form']} onSubmit={formSubmitHandler}>
            {!isLoading && !error && !success && (
                <Fragment>
                    <SecondaryTitle content={{en: 'Write Review', sp: 'Crear Reseña'}}/>
                    <label htmlFor="file-input">
                        <img
                            src={formState.imagePreview || placeholderImg}
                            className={styles['review-form-image']}
                            alt="Product"
                            style={{cursor: 'pointer'}}
                        />
                    </label>
                    <input type="file" accept="image/*" style={{display: 'none'}} id="file-input" onChange={imageChangeHandler}/>
                    <ReviewRating rating={rating}/>
                    <Select label={reviewRating.labels}
                            isTouched={ratingTouched}
                            isValid={ratingValid}
                            value={rating}
                            errorMessage={reviewRating.errorMessages}
                            placeholder={reviewRating.placeholders}
                            inputChangeHandler={inputChangeHandler}
                            inputTouchedHandler={inputTouchedHandler}
                            dataTarget={'RATING'}
                            options={[{value: 5, text: {en: '5', sp: '5'}},
                                {value: 4, text: {en: '4', sp: '4'}},
                                {value: 3, text: {en: '3', sp: '3'}},
                                {value: 2, text: {en: '2', sp: '2'}},
                                {value: 1, text: {en: '1', sp: '1'}}]}/>
                    <Input label={reviewTitle.labels}
                           type={'text'}
                           isTouched={titleTouched}
                           isValid={titleValid}
                           value={title}
                           inputChangeHandler={inputChangeHandler}
                           inputTouchedHandler={inputTouchedHandler}
                           placeholder={reviewTitle.placeholders}
                           errorMessage={reviewTitle.errorMessages}
                           dataTarget={'TITLE'}/>
                    <Textarea label={reviewMessage.labels}
                              isTouched={messageTouched}
                              isValid={messageValid}
                              value={message}
                              inputChangeHandler={inputChangeHandler}
                              inputTouchedHandler={inputTouchedHandler}
                              placeholder={reviewMessage.placeholders}
                              errorMessage={reviewMessage.errorMessages}
                              dataTarget={'MESSAGE'}/>
                    <div className={styles['review-form-controller']}>
                        <button type={'button'} onClick={toggleReviewFormModal}>
                            {activeLang ? 'Cancel' : 'Cancelar'}
                        </button>
                        <button type={'submit'} disabled={!formValid}>
                            {activeLang ? 'Post Review' : 'Publicar Reseña'}
                        </button>
                    </div>
                </Fragment>
            )}
            {isLoading && <Loader classes={styles['review-form-loader']} loadingMessage={{en: 'Posting Review...', sp: 'Publicando Reseña...'}}/>}
            {success && <SuccessFeedback classes={styles['review-form-success']} successMessage={{en: 'Review Posted!', sp: '¡Reseña Publicada!...'}}/>}
            {error && <ErrorFeedback classes={styles['review-form-error']} errorMessage={{en: 'Error Trying To Post Review...', sp: 'Error Al Intentar Publicar Reseña...'}}/>}
        </form>
    )

}

export default ReviewForm