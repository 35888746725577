import React, {useCallback, useContext} from 'react';
import { LangContext } from "../../../../store/lang-context";
import { Icon } from "@iconify/react";
import styles from './ErrorFeedback.module.css';

function ErrorFeedback(props) {

    // Props
    const { classes, errorMessage } = props

    // Context
    const { activeLang } = useContext(LangContext)

    // Helper Functions
    const getClasses = useCallback(() => classes ? `${classes} ${styles['error']}` : styles['error'], [])

    return (
        <div className={getClasses()}>
            <Icon className={styles['error-icon']} icon="mage:robot-dead" />
            <p className={styles['error-text']}>
                {errorMessage && (activeLang ? errorMessage.en : errorMessage.sp)}
                {!errorMessage && (activeLang ? 'Oops! We had a problem, try again' : '¡Ups! Tuvimos un problema, intenta de nuevo')}
            </p>
        </div>
    );
}

export default ErrorFeedback;
