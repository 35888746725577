import { useState, useCallback } from 'react';

const baseUrl = process.env.REACT_APP_PRESTWATCH_API_URL

function useHttpForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState(false);

    const sendRequest = useCallback(async (url, formData) => {
        setIsLoading(true);
        setError(false);
        setSuccess(false);
        setData(false);

        try {
            const response = await fetch(baseUrl + url, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`${response.status} ${response.statusText}`);
            }
            const responseData = await response.json();
            setData(responseData);
            setSuccess(true);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    return { isLoading, error, success, data, sendRequest };
}

export default useHttpForm;