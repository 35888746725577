import React from 'react'
import ProductCard from "./ProductCard/ProductCard";
import styles from './ProductGallery.module.css'

function ProductGallery(props){

    // Props
    const { baseUrl, products } = props

    return (
        <div className={styles['product-gallery']}>
            {products && products.map((product, index) => {
                return <ProductCard key={index} product={product} baseUrl={baseUrl}/>
            })}
        </div>
    )
}

export default ProductGallery