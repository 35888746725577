import React, {useState, useEffect, useContext, useReducer, Fragment} from 'react'
import { LangContext } from "../../../store/lang-context";
import useHttp from "../../../hooks/useHttp";
import SecondaryTitle from "../../UI/Text/SecondaryTitle";
import Input from "../../UI/Forms/Input/Input";
import Select from "../../UI/Forms/Select/Select";
import Textarea from "../../UI/Forms/Textarea/Textarea";
import Loader from "../../UI/Loader/Loader";
import SuccessFeedback from "../../UI/Feedback/SuccessFeedback/SuccessFeedback";
import ErrorFeedback from "../../UI/Feedback/ErrorFeedback/ErrorFeedback";
import styles from './QuotationForm.module.css'

const initialState = {
    firstName: null, firstNameTouched: null, firstNameValid: null,
    lastName: null, lastNameTouched: null, lastNameValid: null,
    email: null, emailTouched: null, emailValid: null,
    phoneNumber: null, phoneNumberTouched: null, phoneNumberValid: null,
    model: null, modelTouched: null, modelValid: null,
    message: null, messageTouched: null, messageValid: null,
}

function quotationFormReducer(prevState, action) {
    switch(action.type) {
        case 'FIRST_NAME_TOUCHED':
            const firstNameValue = prevState.firstName;
            return {
                ...prevState,
                firstNameTouched: true,
                firstNameValid: firstNameValue !== null && firstNameValue !== ''
            };
        case 'FIRST_NAME_INPUT':
            return {
                ...prevState,
                firstName: action.value,
                firstNameTouched: true,
                firstNameValid: action.value !== ''
            };

        case 'LAST_NAME_TOUCHED':
            const lastNameValue = prevState.lastName;
            return {
                ...prevState,
                lastNameTouched: true,
                lastNameValid: lastNameValue !== null && lastNameValue !== ''
            };
        case 'LAST_NAME_INPUT':
            return {
                ...prevState,
                lastName: action.value,
                lastNameTouched: true,
                lastNameValid: action.value !== ''
            };

        case 'EMAIL_TOUCHED':
            const emailValue = prevState.email;
            return {
                ...prevState,
                emailTouched: true,
                emailValid: emailValue !== null && emailValue.includes('@')
            };
        case 'EMAIL_INPUT':
            return {
                ...prevState,
                email: action.value,
                emailTouched: true,
                emailValid: action.value.includes('@')
            };

        case 'PHONE_NUMBER_TOUCHED':
            const phoneNumberValue = prevState.phoneNumber;
            return {
                ...prevState,
                phoneNumberTouched: true,
                phoneNumberValid: phoneNumberValue !== null && phoneNumberValue !== ''
            };
        case 'PHONE_NUMBER_INPUT':
            return {
                ...prevState,
                phoneNumber: action.value,
                phoneNumberTouched: true,
                phoneNumberValid: action.value !== ''
            };

        case 'MODEL_TOUCHED':
            const modelValue = prevState.model;
            return {
                ...prevState,
                modelTouched: true,
                modelValid: modelValue !== null
            };
        case 'MODEL_INPUT':
            return {
                ...prevState,
                model: parseInt(action.value),
                modelTouched: true,
                modelValid: action.value !== ''
            };

        case 'MESSAGE_TOUCHED':
            const messageValue = prevState.message;
            return {
                ...prevState,
                messageTouched: true,
                messageValid: messageValue !== null && messageValue !== ''
            };
        case 'MESSAGE_INPUT':
            return {
                ...prevState,
                message: action.value,
                messageTouched: true,
                messageValid: action.value !== ''
            };
        default:
            return prevState;
    }
}
function QuotationForm(props){

    // Props
    const { products, brands, displayModelHandler } = props

    // Context
    const { activeLang } = useContext(LangContext)

    // States
    const [ formattedBrands, setFormattedBrands ] = useState([])
    const [ filteredProducts, setFilteredProducts ] = useState([])
    const [ selectedBrand, setSelectedBrand ] = useState(null)
    const [ formValid, setFormValid ] = useState(false)
    const [ formState, stateDispatcher ] = useReducer(quotationFormReducer, initialState)
    const { firstName, firstNameTouched, firstNameValid, lastName, lastNameTouched, lastNameValid,
            email, emailTouched, emailValid, phoneNumber, phoneNumberValid, phoneNumberTouched,
            model, modelTouched, modelValid, message, messageTouched, messageValid } = formState

    // HTTP Hooks
    const { isLoading, success, error, data, sendRequest } = useHttp()

    useEffect(() => {
        setFormattedBrands(brands.map(brand =>
            ({ value: brand?.name, text: {en: brand?.name, sp: brand?.name}})
        ))
    }, [])

    useEffect(() => {
        if (selectedBrand && products) {
            const filtered = products
                .filter(product => product.name === selectedBrand)
                .flatMap(product => product.models)
                .map(model => ({
                    image: model.images[0]?.image,
                    value: model.id,
                    text: { en: model.name, sp: model.name }
                }));
            setFilteredProducts(filtered.length > 0 ? filtered : []);
        }
    }, [selectedBrand]);

    useEffect(() => {
        setFormValid(firstNameValid && lastNameValid && emailValid &&
                     phoneNumberValid && modelValid && messageValid)
    }, [firstNameValid, lastNameValid, emailValid, phoneNumberValid, modelValid, messageValid]);

    // Event Handlers
    const formSubmitHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        const formData = {first_name: firstName, last_name: lastName, email: email,
                          phone_number: phoneNumber, model: model, comments: message}
        sendRequest('/quotations/', {method: 'POST', body: formData})
    }

    const inputTouchedHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        const target = event.target.getAttribute('data-target')
        stateDispatcher({type: `${target}_TOUCHED`})
    }

    const inputChangeHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        const target = event.target.getAttribute('data-target')
        const value = event.target.value
        stateDispatcher({type: `${target}_INPUT`, value:value})
    }

    const brandSelectHandler = (event) => {
        const brand = event.target.selectedOptions[0].text
        displayModelHandler(null)
        setSelectedBrand(brand)
    }

    const modelSelectHandler = (event) => {
        const target = event.target.getAttribute('data-target')
        const model = event.target.value
        stateDispatcher({type: `${target}_INPUT`, value:model})
        const selectedModel = filteredProducts.find(product => product.value === parseInt(model));
        displayModelHandler(selectedModel)
    }

    return (
        <form className={styles['quotation-form']} onSubmit={formSubmitHandler}>
            <SecondaryTitle content={{en: 'Quotation', sp: 'Cotizacion'}}/>
            {isLoading && <Loader loadingMessage={{en: 'Requesting Quotation...', sp: 'Solicitando Cotizacion...'}}/>}
            {success && <SuccessFeedback successMessage={{en: 'Quotation Request Sent', sp: 'Solicitud de Cotizacion Enviada'}}/>}
            {error && <ErrorFeedback errorMessage={{en: 'We had a problem, please try again', sp: 'Tuvimos un problema, por favor intenta de nuevo'}}/>}
            {!isLoading && !success && !data && !error &&
                <Fragment>
                  <Input label={{en: 'Name', sp: 'Nombre'}}
                       type={'text'}
                       value={firstName}
                       isTouched={firstNameTouched}
                       isValid={firstNameValid}
                       required={true}
                       inputTouchedHandler={inputTouchedHandler}
                       inputChangeHandler={inputChangeHandler}
                       errorMessage={{en: 'Provide A Valid Name', sp: 'Ingrese Un Nombre Valido'}}
                       placeholder={{en: 'Provide Your Name', sp: 'Ingresa Tu Nombre'}}
                       dataTarget={'FIRST_NAME'}/>
                <Input label={{en: 'Last Name', sp: 'Apellido'}}
                       type={'text'}
                       value={lastName}
                       isTouched={lastNameTouched}
                       isValid={lastNameValid}
                       required={true}
                       inputTouchedHandler={inputTouchedHandler}
                       inputChangeHandler={inputChangeHandler}
                       errorMessage={{en: 'Provide A Valid Last Name', sp: 'Ingrese Un Apellido Valido'}}
                       placeholder={{en: 'Provide Your Last Name', sp: 'Ingresa Tu Apellido'}}
                       dataTarget={'LAST_NAME'}/>
                <Input label={{en: 'Email', sp: 'Correo Electronico'}}
                       type={'email'}
                       value={email}
                       isTouched={emailTouched}
                       isValid={emailValid}
                       required={true}
                       inputTouchedHandler={inputTouchedHandler}
                       inputChangeHandler={inputChangeHandler}
                       errorMessage={{en: 'Provide A Valid Email', sp: 'Ingrese Un Correo Valido'}}
                       placeholder={{en: 'Provide Your Email', sp: 'Ingresa Tu Correo Electronico'}}
                       dataTarget={'EMAIL'}/>
                <Input label={{en: 'Phone Number', sp: 'Numero Telefonico'}}
                       type={'text'}
                       value={phoneNumber}
                       isTouched={phoneNumberTouched}
                       isValid={phoneNumberValid}
                       required={true}
                       inputTouchedHandler={inputTouchedHandler}
                       inputChangeHandler={inputChangeHandler}
                       errorMessage={{en: 'Provide A Valid Phone Number', sp: 'Ingresa Un Numero Valido'}}
                       placeholder={{en: 'Provide Your Phone Number', sp: 'Ingresa Tu Numero Telefonico'}}
                       dataTarget={'PHONE_NUMBER'}/>
                <Select label={{en: 'Brand', sp: 'Marca'}}
                        options={formattedBrands}
                        placeholder={{en: 'Select A Brand', sp: 'Seleccione Una Marca'}}
                        inputChangeHandler={brandSelectHandler}/>
                <Select label={{en: 'Model',  sp: 'Modelo'}}
                        value={model}
                        isTouched={modelTouched}
                        isValid={modelValid}
                        required={true}
                        options={filteredProducts}
                        placeholder={{en: 'Select A Model', sp: 'Seleccione Un Modelo'}}
                        errorMessage={{en: 'Select a Valid Model', sp: 'Seleccione Un Modelo Valido'}}
                        inputTouchedHandler={inputTouchedHandler}
                        inputChangeHandler={modelSelectHandler}
                        dataTarget={'MODEL'}/>
                <Textarea label={{en: 'Message', sp: 'Mensaje'}}
                          value={message}
                          isTouched={messageTouched}
                          isValid={messageValid}
                          required={true}
                          inputTouchedHandler={inputTouchedHandler}
                          inputChangeHandler={inputChangeHandler}
                          errorMessage={{en: 'Provide Us A Message', sp: 'Proveanos un Mensaje'}}
                          placeholder={{en: 'Do you have any questions or comments?', sp: '¿Tienes alguna pregunta o comentario?'}}
                          dataTarget={'MESSAGE'}/>
                <button type={'submit'} disabled={!formValid}>{activeLang ? 'Request Quotation' : 'Realizar Cotizacion'}</button>
                </Fragment>}
        </form>
    )
}

export default QuotationForm