import React from 'react'
import SecondaryTitle from "../../../UI/Text/SecondaryTitle";
import Text from "../../../UI/Text/Text";
import styles from './BrandProfile.module.css'

function BrandProfile(props){

    // Props
    const { baseUrl, brand } = props

    return (
        <div className={styles['brand-profile']}>
            <img className={styles['brand-banner']} src={`${baseUrl}${brand?.banner}`} alt={'Banner'}/>
            <div className={styles['brand-info']}>
                <img className={styles['brand-picture']} src={`${baseUrl}${brand?.logo}`} alt={'Brand Logo'}/>
                <div className={styles['brand-description']}>
                    <SecondaryTitle content={{en: brand?.name, sp: brand?.name}}/>
                    <Text content={{
                        en: brand?.description,
                        sp: brand?.description_sp
                    }}/>
                </div>
            </div>
        </div>
    )

}

export default BrandProfile