import React, {useContext} from 'react'
import { LangContext } from "../../../store/lang-context";

function Text(props){

    // Props
    const { content, classes } = props

    // Context
    const { activeLang } = useContext(LangContext)

    return (
        <p className={classes && classes}>
            {activeLang ? content.en : content.sp}
        </p>
    )
}

export default Text