import React, { Fragment, useContext } from 'react'
import { useParams } from "react-router-dom";
import SecondaryTitle from "../../../UI/Text/SecondaryTitle";
import { LangContext } from "../../../../store/lang-context";
import styles from './ProductFilter.module.css'

function ProductFilter(props){

    // Props
    const { brands, onFilterChange, onSortChange, selectedBrand, selectedSort } = props;

    // URL Paramaters
    const { brandId } = useParams()

    // Context
    const { activeLang } = useContext(LangContext);

    // Event Handlers for filter and sort changes
    const handleBrandChange = (event) => {
        onFilterChange(event.target.value);  // Trigger filter change
    };

    const handleSortChange = (event) => {
        onSortChange(event.target.value);    // Trigger sort change
    };

    return (
        <div className={styles['product-filter']}>
            <SecondaryTitle classes={styles['filter-title']}
                            content={{en: 'Filter Products', sp: 'Filtrar Productos'}}/>
            <label className={styles['sort-by']} htmlFor={'sort-by'}>{activeLang ? 'Order By' : 'Ordenar Por'}</label>
            <select name={'sort-by'} id={'sort-by'} value={selectedSort} onChange={handleSortChange}>
                <option value={-1}>{activeLang ? 'Price (Descending Order)' : 'Precio (Orden Descendente)'}</option>
                <option value={1}>{activeLang ? 'Price (Ascending Order)' : 'Precio (Orden Ascendente)'}</option>
            </select>
            {!brandId &&
                <Fragment>
                    <label className={styles['brand']} htmlFor={'brand'}>{activeLang ? 'Brand' : 'Marca'}</label>
                    <select name={'brand'} id={'brand'} value={selectedBrand} onChange={handleBrandChange}>
                        <option value="">{activeLang ? 'All Brands' : 'Todas las Marcas'}</option>
                        {brands && brands.map((brand, index) => {
                            return <option key={index} value={brand.name}>{brand.name}</option>
                        })}
                    </select>
                </Fragment>
            }
        </div>
    )
}

export default ProductFilter;