import React, { Fragment } from 'react'
import { Icon } from "@iconify/react";
import styles from './ReviewRating.module.css'

function ReviewRating(props){

    const { rating } = props

    return (
        <div className={styles['rating']}>
            {(!rating || rating === 0) && (
                <Fragment>
                    <Icon className={styles['rating-star-blank']} icon="line-md:star"/>
                    <Icon className={styles['rating-star-blank']} icon="line-md:star"/>
                    <Icon className={styles['rating-star-blank']} icon="line-md:star"/>
                    <Icon className={styles['rating-star-blank']} icon="line-md:star"/>
                    <Icon className={styles['rating-star-blank']} icon="line-md:star"/>
                </Fragment>
            )}
            {rating >= 1 && <Icon className={styles['rating-star']} icon="twemoji:star"/>}
            {rating >= 2 && <Icon className={styles['rating-star']} icon="twemoji:star"/>}
            {rating >= 3 && <Icon className={styles['rating-star']} icon="twemoji:star"/>}
            {rating >= 4 && <Icon className={styles['rating-star']} icon="twemoji:star"/>}
            {rating >= 5 && <Icon className={styles['rating-star']} icon="twemoji:star"/>}
        </div>
    )

}

export default ReviewRating