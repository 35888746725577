import React, { Fragment, useState, useEffect } from 'react';
import useHttp from "../../../hooks/useHttp";
import ProductFilter from "./ProductFilter/ProductFilter";
import ProductGallery from "./ProductGallery/ProductGallery";
import ErrorFeedback from "../../UI/Feedback/ErrorFeedback/ErrorFeedback";
import NoDataFeedBack from "../../UI/Feedback/NoDataFeedback/NoDataFeedBack";
import styles from './ProductCatalog.module.css';

function ProductCatalog(props) {

    // Props
    const { products } = props;

    // HTTP Hook
    const { baseUrl, success, error, data, sendRequest } = useHttp();

    // States
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedSort, setSelectedSort] = useState(-1);

    // Effects
    useEffect(() => {
        if (!products) {
            sendRequest('/products');
        } else {
            setFilteredProducts(products);
        }
    }, [products, sendRequest]);

    useEffect(() => {
        if (products || (success && data)) {
            let updatedProducts = products ? [...products] : [...data.products];

            if (selectedBrand) {
                updatedProducts = updatedProducts.filter(product => product.model.brand === selectedBrand);
            }

            updatedProducts.sort((a, b) => {
                return selectedSort === '1'
                    ? a.price - b.price
                    : b.price - a.price;
            });

            setFilteredProducts(updatedProducts);
        }
    }, [products, success, data, selectedBrand, selectedSort]);

    // Event Handlers
    const handleFilterChange = (brandId) => {
        setSelectedBrand(brandId);
    };

    const handleSortChange = (sortOrder) => {
        setSelectedSort(sortOrder);
    };

    return (
        <Fragment>
            {error && <ErrorFeedback />}
            {((success || products) && (
                <div className={styles['product-catalog']}>
                    <ProductFilter
                        brands={data?.brands || products.map(p => p.model.brand)} // Brands from API or products prop
                        onFilterChange={handleFilterChange}
                        onSortChange={handleSortChange}
                        selectedBrand={selectedBrand}
                        selectedSort={selectedSort}
                    />
                    {filteredProducts.length > 0 && <ProductGallery products={filteredProducts} baseUrl={baseUrl} />}
                    {filteredProducts.length === 0 && <NoDataFeedBack noDataMessage={{en: `Whoops! We don't have products that meet this criteria`,
                                                                                      sp: '¡Ups! No tenemos productos de esta marca en stock'}}/>}
                </div>
            ))}
        </Fragment>
    );
}

export default ProductCatalog;