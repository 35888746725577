import React from 'react'
import { Routes, Route } from "react-router-dom";
import ProductCatalog from "../../../components/Products/ProductCatalog/ProductCatalog";
import ProductDetail from "../ProductDetail/ProductDetail";
import styles from './AllProducts.module.css'

function AllProducts(){

    return (
        <div className={styles['all-products']}>
            <Routes>
                <Route path={''} element={<ProductCatalog/>}/>
                <Route path={':productId'} element={<ProductDetail/>}/>
            </Routes>
        </div>
    )

}

export default AllProducts