import React, { useContext } from 'react';
import { LangContext } from "../../../store/lang-context";
import { Icon } from "@iconify/react";
import { useLocation } from 'react-router-dom';  // Import useLocation hook
import styles from './ContactSeller.module.css';

function ContactSeller(props) {

    // Context
    const { activeLang } = useContext(LangContext);

    // Get current location from React Router
    const location = useLocation();
    const currentUrl = encodeURIComponent(`${window.location.origin}${location.pathname}`);  // Construct full URL

    // Define the message based on activeLang (true = English, false = Spanish)
    const whatsappMessage = activeLang
        ? `Hello%2C%20I%20am%20interested%20in%20your%20products%21%20Check%20this%20link%3A%20${currentUrl}%20Could%20you%20provide%20more%20details%3F`
        : `Hola%2C%20estoy%20interesado%20en%20sus%20productos%21%20Aquí%20tiene%20el%20enlace%3A%20${currentUrl}%20Podría%20darme%20más%20detalles%3F`;

    return (
        <a href={`https://wa.me/33099408?text=${whatsappMessage}`} target={'_blank'} rel="noopener noreferrer">
            <button className={styles['contact-seller-button']}>
                <Icon icon="logos:whatsapp-icon" width="24" height="24" />
                <span>{activeLang ? 'Contact Seller' : 'Contactar Vendedor'}</span>
                <span></span>
            </button>
        </a>
    );
}

export default ContactSeller;