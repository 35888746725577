import React, { useEffect } from 'react'
import Faq from "../../components/FAQs/Faq/Faq";
import SecondaryTitle from "../../components/UI/Text/SecondaryTitle";
import useHttp from "../../hooks/useHttp";
import Loader from "../../components/UI/Loader/Loader";
import NoDataFeedBack from "../../components/UI/Feedback/NoDataFeedback/NoDataFeedBack";
import ErrorFeedback from "../../components/UI/Feedback/ErrorFeedback/ErrorFeedback";
import styles from './Faqs.module.css'

function Faqs(){

    const { isLoading, error, success, data, sendRequest } = useHttp()

    useEffect(() => {
        sendRequest('/faq')
    }, []);

    return (
        <div className={styles['faqs']}>
            <SecondaryTitle content={{en: 'Frequently Asked Questions', sp: 'Preguntas Frecuentes'}}/>
            {isLoading && <Loader/>}
            {error && !success && !data && <ErrorFeedback/>}
            {!error && success && data.faqs?.length === 0 && <NoDataFeedBack/>}
            {!error && success && data && data.faqs.map((faq, index) => {
                return <Faq key={index} question={{en: faq.question, sp: faq.question_sp,}} answer={{en: faq.answer, sp: faq.answer_sp}}/>
            })}
        </div>
    )
}

export default Faqs