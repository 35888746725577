import React from 'react'
import { Icon } from "@iconify/react";
import SecondaryTitle from "../../../components/UI/Text/SecondaryTitle";
import Text from "../../../components/UI/Text/Text";
import useHttp from "../../../hooks/useHttp";
import styles from './ReviewDetail.module.css'

function ReviewDetail(props){

    // Props
    const { review } = props
    const { rating } = review

    // Base URL
    const { baseUrl } = useHttp()

    return (
        <div className={styles['review-detail']}>
            {review.images.length > 0 && <img src={baseUrl + review.images[0].image} className={styles['review-image']} alt={'Review Proof'}/>}
            <div className={styles['rating']}>
                {rating >= 1 && <Icon className={styles['rating-star']} icon="twemoji:star"/>}
                {rating >= 2 && <Icon className={styles['rating-star']} icon="twemoji:star"/>}
                {rating >= 3 && <Icon className={styles['rating-star']} icon="twemoji:star"/>}
                {rating >= 4 && <Icon className={styles['rating-star']} icon="twemoji:star"/>}
                {rating >= 5 && <Icon className={styles['rating-star']} icon="twemoji:star"/>}
            </div>
            <SecondaryTitle content={{en: review.title, sp: review.title}}/>
            <Text content={{en: review.review, sp: review.review}} />
        </div>
    )
}

export default ReviewDetail