import React, { Fragment } from 'react'
import SecondaryTitle from "../../UI/Text/SecondaryTitle";
import Text from "../../UI/Text/Text";
import styles from './ProductDisplay.module.css'

function ProductDisplay(props){

    // Props
    const { baseUrl, model } = props

    return (
        <div className={styles['product-display']}>
            <SecondaryTitle content={{en: 'Product', sp: 'Producto'}}/>
            {!model &&
                <Fragment>
                    <Text classes={styles['product-name']}
                          content={{en: 'Product preview here',
                                    sp: 'Vista previo del producto aca'}}/>
                </Fragment>
            }
            {model &&
                <Fragment>
                    <img src={`${baseUrl}${model.image}`} className={styles['product-preview']} alt={'Product Display'}/>
                    <Text classes={styles['product-name']}
                          content={{en: model.text.en,
                                    sp: model.text.sp}}/>
                </Fragment>
            }
        </div>
    )

}

export default ProductDisplay