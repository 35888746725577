import React, {useState} from 'react'

export const LangContext = React.createContext({
    activeLang: false,
    toggleLangHandler: () => {}
})

function LangContextProvider(props){

    const { children } = props

    const [activeLang, setActiveLang] = useState(false)

    const toggleLangHandler = () => {
        setActiveLang((prevState) => {
            return !prevState
        })
    }

    return (
        <LangContext.Provider value={{activeLang: activeLang, toggleLangHandler: toggleLangHandler}}>
            { children }
        </LangContext.Provider>
    )

}

export default LangContextProvider