import React, { useEffect, useContext } from 'react'
import { Routes, Route } from "react-router-dom";
import { LangContext } from "./store/lang-context";
import Layout from "./components/UI/Layout/Layout";
import Home from "./pages/Home/Home";
import Products from "./pages/Products/Products";
import Faqs from "./pages/FAQs/Faqs";
import Quotations from "./pages/Quotations/Quotations";
import ContactUs from "./pages/ContactUs/ContactUs";
import Reviews from "./pages/Reviews/Reviews";

function App() {

    const { activeLang } = useContext(LangContext)

    useEffect(() => {
        if (activeLang){
            document.querySelector('title').text = 'PrestWatch - Every Second Counts, Wear It Well'
        }else{
            document.querySelector('title').text = 'PrestWatch - Cada Segundo Cuenta, Llévalo con Estilo'
        }
    }, [activeLang])

    return (
      <Layout>
        <Routes>
            <Route path={''} element={<Home/>}/>
            <Route path={'products/*'} element={<Products/>}/>
            <Route path={'quotations'} element={<Quotations/>}/>
            <Route path={'reviews'} element={<Reviews/>}/>
            <Route path={'faqs'} element={<Faqs/>}/>
            <Route path={'contact-us'} element={<ContactUs/>}/>
        </Routes>
      </Layout>
    );
}

export default App;
