import React, { useState } from 'react'
import ReviewUser from "./ReviewUser/ReviewUser";
import ReviewRating from "../../ReviewRating/ReviewRating";
import Text from "../../../UI/Text/Text";
import styles from './ReviewCard.module.css'
import Modal from "../../../UI/Modal/Modal";
import ReviewDetail from "../../ReviewDetail/ReviewDetail";

function ReviewCard(props){

    // Props
    const { review } = props

    // States
    const [ reviewModalActive, setReviewModalActive ] = useState(false)

    // Event Handlers
    const toggleReviewModalHandler = (event) => {
        event.stopPropagation()
        setReviewModalActive((prevState) => {
            return !prevState
        })
    }

    return (
        <div className={styles['review-card']} onClick={toggleReviewModalHandler}>
            <ReviewUser/>
            <ReviewRating rating={review.rating}/>
            <Text classes={styles['title']} content={{en: review.title, sp: review.title}}/>
            <small>{review.review}</small>
            <Modal modalOpened={reviewModalActive} onCloseModal={toggleReviewModalHandler}>
                <ReviewDetail review={review}/>
            </Modal>
        </div>
    )
}

export default ReviewCard