import React from 'react'
import { Icon } from "@iconify/react";
import SecondaryTitle from "../../../components/UI/Text/SecondaryTitle";
import Text from "../../../components/UI/Text/Text";
import styles from './Social.module.css'

function Social(){
    return (
        <div className={styles['social']}>
            <SecondaryTitle content={{en: 'Follow Us', sp: 'Siguenos'}}/>
            <Text content={{en: 'Keep up to date with our latest products and tendencies on our social media',
                            sp: 'Mantente al tanto de nuestros ultimos productos y tendencias en nuestras redes sociales'}}/>
            <div className={styles['social-links']}>
                {/*<a href={'https://www.whatsapp.com'} target={'_blank'} rel={'noreferrer'}>*/}
                {/*    <Icon icon="fa6-brands:square-whatsapp" className={styles['social-link']} style={{color: '#FFFFFF'}} />*/}
                {/*</a>*/}
                <a href={'https://www.instagram.com/prestwatch/'} target={'_blank'} rel={'noreferrer'}>
                    <Icon icon="teenyicons:instagram-solid" className={styles['social-link']}  style={{color: '#FFFFFF'}} />
                </a>
            </div>
        </div>
    )
}

export default Social