import React from 'react'
import ContactUsForm from "../../components/ContactUs/ContactUsForm/ContactUsForm";
import styles from './ContactUs.module.css'

function ContactUs(){
    return (
        <div className={styles['contact-us']}>
            <ContactUsForm/>
        </div>
    )
}

export default ContactUs