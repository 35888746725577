import React, { useEffect } from 'react'
import useHttp from "../../../hooks/useHttp";
import Loader from "../../../components/UI/Loader/Loader";
import SecondaryTitle from "../../../components/UI/Text/SecondaryTitle";
import BrandTile from "../../../components/Home/OurBrands/BrandTile/BrandTile";
import NoDataFeedBack from "../../../components/UI/Feedback/NoDataFeedback/NoDataFeedBack";
import ErrorFeedback from "../../../components/UI/Feedback/ErrorFeedback/ErrorFeedback";
import styles from './OurBrands.module.css'

function OurBrands(){

    const { baseUrl, isLoading, error, success, data, sendRequest } = useHttp()

    useEffect(() => {
        sendRequest('/products/brands')
    }, []);

    return (
        <div className={styles['our-brands']}>
            <SecondaryTitle content={{en: 'Our Brands', sp: 'Nuestras Marcas'}}/>
            {isLoading && <Loader/>}
            {error && !success && !data && <ErrorFeedback/>}
            {!error && success && data.brands?.length === 0 && <NoDataFeedBack/>}
            {!error && success && data && (
                <div className={styles['brands-gallery']}>
                    {data.brands.map((brand, index) => {
                        return <BrandTile key={index} brand={{image: baseUrl + brand.logo, name: brand.name}}/>
                    })}
                </div>
            )}
        </div>
    )

}

export default OurBrands