import React, { useState } from 'react'
import NavBar from "../NavBar/NavBar";
import Modal from './../Modal/Modal'
import Menu from "../Menu/Menu";
import styles from './Layout.module.css'

function Layout(props){

    const { children } = props
    const [ modalOpened, setModalOpened ] = useState(false)

    const toggleModalHandler = () => {
        setModalOpened((prevState) => {
            return !prevState
        })
    }

    return (
        <div className={styles['layout']}>
            <NavBar onToggleModal={toggleModalHandler}/>
            { children }
            <Modal modalOpened={modalOpened} classes={styles['modal-styles']} onCloseModal={toggleModalHandler}>
                <Menu onCloseModal={toggleModalHandler} modalOpened={modalOpened}/>
            </Modal>
        </div>
    )
}

export default Layout