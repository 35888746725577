import React, {useContext} from 'react'
import { LangContext } from "../../../store/lang-context";

function SecondaryTitle(props){

    // Props
    const { content, classes } = props

    // Context
    const { activeLang } = useContext(LangContext)

    return (
        <h2 className={`${classes && classes}`}>{activeLang ? content['en'] : content['sp']} </h2>
    )
}

export default SecondaryTitle